<template>
  <div>
    <!-- Add New Sidebar -->
    <app-channel-list-sidebar
      :is-app-channel-list-sidebar-active.sync="isAppChannelListSidebarActive"
      :data="channelInfo"
      :app-region="appRegion"
      :channels="supportedChannles"
      @updated="queryChannelsByApp"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-button
          variant="outline-primary"
          :to="{ name: 'merchant-apps', query: {merchant_no: merchantNo}, params: { merchant_name: merchantName } }"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>

        <b-button
          class="ml-2"
          variant="outline-primary"
          @click="addNewChannel"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>

        <b-button
          v-if="$can('write', 'Merchants.Fee')"
          class="ml-2"
          variant="outline-primary"
          @click="genDefaultConfirm"
        >
          <feather-icon
            icon="AlignCenterIcon"
            class="mr-50"
          />
          <span class="align-middle">Default Generator</span>
        </b-button>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="channels"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Customer Name -->
        <template #cell(custom_name)="data">
          <b-media vertical-align="center">
            <b-link v-if="$can('read', 'Merchants.Fee')"
                    :to="{ name: 'merchant-app-channel-fees',
                           query: { merchant_no: merchantNo, app_id: appId, region: data.item.region, method: data.item.method, channel: data.item.channel },
                           params: { merchant_name: merchantName, app_name: appName, channel_name: data.item.channel_name }
                    }"
                    class="font-weight-bold d-block"
            >
              {{ data.item.custom_name }}
            </b-link>
            <b-link v-else
                    class="font-weight-bold d-block"
            >
              {{ data.item.custom_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.channel_name }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />

            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BMedia, BLink, BBadge,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'
import AppChannelListSidebar from './AppChannelListSidebar.vue'

const {
  fetchAppChannels,
  fetchAppSupportedChannels,
  listGlobalSubChannels,
  deleteAppChannel,
  copyAppChannelConfig,
} = useMerchantsJs()

export default {
  components: {
    AppChannelListSidebar,

    BCard,
    BTable,
    BButton,
    BMedia,
    BLink,
    BBadge,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      appId: this.$route.query.app_id,
      appName: this.$route.params.app_name,
      appRegion: this.$route.params.region,
      channels: [],
      tableFields: [
        { key: 'custom_name', label: 'CUSTOM NAME' },
        { key: 'region_name', label: 'REGION' },
        { key: 'method', label: 'METHOD' },
        { key: 'channel', label: 'CHANNEL' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],
      supportedChannles: [],

      isAppChannelListSidebarActive: false,
      channelInfo: {},
    }
  },
  created() {
    this.queryChannelsByApp()

    // 获取APP支持渠道列表
    if (this.appRegion === 'GLB') {
      listGlobalSubChannels(
        this.appId,
        channels => {
          this.supportedChannles = channels
        },
        fail => {
          this.showAlert('warning', 'fetch supported channel list fail!', `${fail}`)
        },
      )
    } else {
      fetchAppSupportedChannels(
        this.appId,
        channels => {
          this.supportedChannles = channels
        },
        fail => {
          this.showAlert('warning', 'fetch supported channel list fail!', `${fail}`)
        },
      )
    }
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    queryChannelsByApp() {
      fetchAppChannels(
        this.appId,
        channels => {
          this.channels = channels
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },
    addNewChannel() {
      this.channelInfo = {
        is_new: true,
        app_id: this.appId,
      }
      this.isAppChannelListSidebarActive = true
    },
    showDetail(channelDetail) {
      this.channelInfo = channelDetail
      this.channelInfo.is_new = false
      this.isAppChannelListSidebarActive = true
    },
    genDefaultConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: 'Input APP ID Copy From',
            input: 'text',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Copy',
          }).then(r => {
            if (r.value) {
              this.genDefault(r.value)
            } else {
              showToast(this, 'Warning', 'No APP ID Inputed.')
            }
          })
        }
      })
    },
    genDefault(appIdCopyFrom) {
      const params = {
        from: appIdCopyFrom,
        to: this.appId,
      }
      copyAppChannelConfig(
        params,
        () => {
          this.showAlert('success', 'Success!', 'Copyed')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    deleteConfirm(channelDetail) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteChannel(channelDetail)
        }
      })
    },
    deleteChannel(channelDetail) {
      deleteAppChannel(
        channelDetail,
        () => {
          this.showAlert('success', 'Success!', 'Deleted.')
          this.queryChannelsByApp()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
