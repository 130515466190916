<template>
  <b-sidebar
    id="app-channel-config-sidebar"
    :visible="isAppChannelListSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-app-channel-list-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          APP Channel Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form class="p-2">
        <!-- Method -->
        <b-form-group
          label="Region"
          label-for="region"
        >
          <v-select
            v-model="appChannelInfo.region"
            input-id="method"
            :options="regionOptions"
            :clearable="false"
            :disabled="!appChannelInfo.isNew"
          />
        </b-form-group>
        <!-- Method -->
        <b-form-group
          label="Method"
          label-for="method"
        >
          <v-select
            v-model="appChannelInfo.method"
            input-id="method"
            :options="methodOptions"
            :clearable="false"
            :disabled="!appChannelInfo.isNew"
          />
        </b-form-group>

        <!-- Name -->
        <b-form-group
          label="Name(Customizable)"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="appChannelInfo.customName"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Use Channel Router -->
        <b-form-group
          class="mt-2 mb-2"
          label-for="use-channel-router"
        >
          <b-form-checkbox
            id="use-channel-router"
            v-model="useChannelRouter"
          >
            Use Channel Router
          </b-form-checkbox>
        </b-form-group>

        <!-- Channel -->
        <b-form-group
          v-if="!useChannelRouter"
          label="Channel"
          label-for="channel-list"
        >
          <v-select
            v-model="appChannelInfo.channelId"
            input-id="channel-list"
            :options="subChannels"
            :reduce="val => val.value"
            :disabled="channelSelect"
            :clearable="false"
          />
        </b-form-group>

        <!-- Channel Group -->
        <b-form-group
          v-if="useChannelRouter"
          label="Channel Group"
          label-for="channel-group"
        >
          <v-select
            v-model="appChannelInfo.channelGroupId"
            input-id="channel-group"
            :options="channelGroupOptions"
            label="group_name"
            :reduce="val => val.group_id"
            :clearable="false"
          />
        </b-form-group>

        <!-- Sort -->
        <b-form-group
          label="Sort"
          label-for="sort"
        >
          <b-form-input
            id="sort"
            v-model="appChannelInfo.sort"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Email Notification -->
        <b-form-group
          label="Email Notification"
          label-for="email-setting"
        >
          <v-select
            v-model="emailSetting"
            input-id="email-setting"
            :options="emailNotificationOptions"
            :clearable="false"
            :searchable="false"
            :reduce="val => val.value"
            multiple
            :close-on-select="false"
          />
        </b-form-group>

        <!-- Lower Limit -->
        <b-form-group
          label="Lower Limit"
          label-for="lower-limit"
        >
          <b-form-input
            id="lower-limit"
            v-model="lowerLimit"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Upper Limit -->
        <b-form-group
          label="Upper Limit"
          label-for="upper-limit"
        >
          <b-form-input
            id="upper-limit"
            v-model="upperLimit"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Settlement Period Setting -->
        <b-form-group
          class="mt-2 mb-2"
          label="Settlement Period"
          label-for="settlement-period"
        >
          <b-input-group append="Days">
            <b-form-input
              v-model="settlementPeriod"
              type="number"
              min="1"
              step="1"
              :formatter="value => parseInt(value ? value : 0)"
              autofocus
              trim
            />
          </b-input-group>
        </b-form-group>

        <!-- Cancellation Setting -->
        <b-form-group
          class="mt-2 mb-1"
          label-for="cancelable"
        >
          <b-form-checkbox
            v-model="cancelable"
          >
            Cancelled In
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="cancelable === true"
          class="mt-1 mb-2"
          label-for="cancelable"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-input
                v-model="cancelValue"
                type="number"
                min="1"
                step="1"
                :formatter="value => parseInt(value ? value : 0)"
                autofocus
                trim
              />
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <v-select
                v-model="cancelUnit"
                :options="cancelUnitOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Payer Verification Configuration -->
        <b-form-group
          class="mt-2 mb-1"
          label-for="payer_verification"
        >
          <b-form-checkbox
            v-model="payerVerification"
          >
            Payer Verification
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="payerVerification === true"
          class="ml-1"
          label="Mandatory Threshold"
          label-for="payer_verification"
        >
          <b-input-group prepend="$" class="input-group-merge">
            <b-form-input
              v-model="payerVerificationThreshold"
              type="number"
              min="1"
              step="1"
              :formatter="value => parseInt(value ? value : 0)"
              autofocus
              trim
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          v-if="payerVerification === true"
          class="ml-1"
        >
          <b-form-checkbox
            v-model="pvFlexible"
          >
            Flexible
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          v-if="pvFlexible === true"
          class="ml-1"
          label="Flexible Threshold"
          label-for="payer_verification"
        >
          <b-row>
            <b-col cols="12" md="5">
              <b-input-group prepend="$" class="input-group-merge">
                <b-form-input
                  v-model="payerVerificationFlexibleThreshold"
                  type="number"
                  min="1"
                  step="1"
                  :formatter="value => parseInt(value ? value : 0)"
                  autofocus
                  trim
                />
              </b-input-group>
            </b-col>
            <b-col cols="12" md="7">
              <b-input-group append="Transaction(s)" class="input-group-merge">
                <b-form-input
                  v-model="pvFlexibleTrans"
                  type="number"
                  min="1"
                  step="1"
                  :formatter="value => parseInt(value ? value : 0)"
                  autofocus
                  trim
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Payer Verification Backup Channel Group -->
        <b-form-group
          v-if="useChannelRouter && payerVerification"
          class="ml-1"
          label="Channel Group"
          label-for="backup-channel-group"
        >
          <v-select
            v-model="payerVerificationBackupChannelGroup"
            input-id="backup-channel-group"
            :options="channelGroupOptions"
            label="group_name"
            :reduce="val => val.group_id"
            :clearable="false"
          />
        </b-form-group>

        <!-- 3DS -->
        <b-form-group
          v-if="appChannelInfo.method === 'CreditCard'"
          label="3DS"
          class="mt-2 mb-2"
          label-for="threeds"
        >
          <v-select
            v-model="threeds"
            :options="switchOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="threeds"
          />
        </b-form-group>

        <!-- Status -->
        <b-form-group
          class="mt-2"
          label="Status"
          label-for="app-status"
        >
          <v-select
            v-model="appChannelInfo.status"
            :options="statusOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="app-status"
          />
        </b-form-group>

        <el-divider class="mt-3 mb-3" />

        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="isSubmitDisabled"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BButton, BForm, BFormCheckbox, BRow, BCol, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { showAlert, showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  updateAppChannel,
  addAppChannel,
  fetchChannelGroupByRegionAndMethod,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppChannelListSidebarActive',
    event: 'update:is-app-channel-list-sidebar-active',
  },
  props: {
    isAppChannelListSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    appRegion: {
      type: String,
      default: () => '',
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : {},
      channelSelect: true,
      appChannelInfo: {
        isNew: false,
        appId: '',
        region: '',
        channel: '',
        channelId: '',
        channelGroupId: '',
        channelName: '',
        customName: '',
        method: '',
        sort: '',
        status: '',
      },
      lowerLimit: -1,
      upperLimit: -1,
      keyInfo: {
        appId: '',
        region: '',
        channel: '',
        method: '',
      },
      regionOptions: [],
      methodOptions: [],
      subChannels: [],
      channelGroupOptions: [],
      emailSetting: '',
      error: '',

      switchOptions: [
        { label: 'Off', value: false },
        { label: 'On', value: true },
      ],

      statusOptions: [
        { label: 'Disable', value: '0' },
        { label: 'Enable', value: '1' },
      ],

      useChannelRouter: false,
      cancelable: false,
      cancelValue: 1,
      cancelUnit: 'm',
      cancelUnitOptions: [
        { label: 'Minutes', value: 'm' },
        { label: 'Hours', value: 'H' },
        { label: 'Days', value: 'D' },
      ],
      payerVerification: false,
      payerVerificationThreshold: 0,
      pvFlexible: false,
      payerVerificationFlexibleThreshold: 0,
      pvFlexibleTrans: 0,
      payerVerificationBackupChannelGroup: '',
      currencyOptions: [],
      settlementPeriod: 0,
      emailNotificationOptions: [
        { label: 'Generated', value: 'GENERATED' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Remind', value: 'REMIND' },
        { label: 'Expired', value: 'EXPIRED' },
        { label: 'Success', value: 'SUCCESS' },
        { label: 'SuccessOverseas', value: 'SUCCESS_OVERSEAS' },
        { label: 'Fail', value: 'FAIL' },
        { label: 'Refused', value: 'REFUSED' },
        { label: 'Chargeback', value: 'CHARGEBACK' },
        { label: 'RefundRefused', value: 'RR' },
      ],
      threeds: false,
    }
  },
  computed: {
    regionChange() {
      return this.appChannelInfo.region
    },
    methodChange() {
      return this.appChannelInfo.method
    },
    isSubmitDisabled() {
      let disabled = true
      disabled = this.appChannelInfo.method === '' || this.appChannelInfo.channelId === ''
      disabled = disabled && this.payerVerification && (this.payerVerificationThreshold === '' || this.payerVerificationThresholdUnit === '')
      return disabled
    },
  },
  watch: {
    isAppChannelListSidebarActive(val) {
      if (!val) {
        this.useChannelRouter = false
        return
      }
      if (this.appRegion === 'GLB') {
        this.regionOptions = Object.keys(this.channels)
      } else {
        this.appChannelInfo.region = this.appRegion
      }

      this.initAppChannelInfo(this.data)
    },
    regionChange(val) {
      if (!val) {
        return
      }
      this.initSelectRegion(val)
      if (this.data.is_new) {
        this.appChannelInfo.method = ''
        this.appChannelInfo.channelId = ''
      }
    },
    methodChange(val) {
      if (!val || val === '') {
        return
      }
      this.initSelectMethod(val)
    },
    useChannelRouter(val) {
      if (val) {
        const params = {
          region: this.appChannelInfo.region,
          method: this.appChannelInfo.method,
        }
        fetchChannelGroupByRegionAndMethod(
          params,
          channelGroups => {
            this.channelGroupOptions = channelGroups
          },
          fail => {
            showToast(this, 'Warning', `fetch channel group fail with ${fail}.`)
          },
        )
      }
    },
  },
  methods: {
    initSelectRegion(val) {
      const types = []
      if (this.appRegion === 'GLB') {
        this.channels[val].forEach(item => {
          types.push(item.value)
        })
      } else {
        this.channels.forEach(item => {
          types.push(item.value)
        })
      }
      this.methodOptions = Array.from(new Set(types))
    },
    initSelectMethod(val) {
      const subChannels = []
      let selected = ''
      if (this.appRegion === 'GLB') {
        this.channels[this.appChannelInfo.region].forEach(item => {
          if (item.value === val || val === '') {
            if (item.status === '0') {
              return
            }
            const subChannel = { label: item.name, value: item.key }
            subChannels.push(subChannel)
            if (selected === '' && item.is_default) {
              selected = item.key
            }
            if (this.appChannelInfo.channelId === item.key) {
              selected = item.key
            }
          }
        })
      } else {
        this.channels.forEach(item => {
          if (item.value === val || val === '') {
            if (item.status === '0') {
              return
            }
            const subChannel = { label: item.name, value: item.key }
            subChannels.push(subChannel)
            if (selected === '' && item.is_default) {
              selected = item.key
            }
            if (this.appChannelInfo.channelId === item.key) {
              selected = item.key
            }
          }
        })
      }
      if (this.userData.userRole !== 'ADMIN_ADMIN') {
        const adminRoles = this.userData.roles ? this.userData.roles : ''
        if (adminRoles.indexOf('AM') > -1 || adminRoles.indexOf('RD') > -1 || adminRoles.indexOf('AD') > -1 || adminRoles.indexOf('FD') > -1) {
          this.channelSelect = false
        }
        if (selected === '') {
          this.channelSelect = false
        }
      } else {
        this.channelSelect = false
      }
      this.subChannels = subChannels
      this.appChannelInfo.channelId = selected
    },

    initAppChannelInfo(info) {
      if (info.is_new) {
        this.appChannelInfo.isNew = true
        this.appChannelInfo.appId = info.app_id
        return
      }
      this.appChannelInfo = {
        isNew: info.is_new,
        appId: info.app_id,
        region: info.region,
        channel: info.channel,
        channelId: info.channel_id,
        channelGroupId: info.channel_group_id,
        channelName: info.channel_name,
        customName: info.custom_name,
        method: info.method,
        sort: info.sort,
        status: info.status,
      }
      if (info.channel === 'CHANNEL_GROUP_ROUTER') {
        this.useChannelRouter = true
        this.appChannelInfo.channelGroupId = info.channel_id
      }
      this.lowerLimit = -1
      this.upperLimit = -1
      if (info.capacity) {
        const capacity = info.capacity.split(',')
        const [lowerLimit, upperLimit] = capacity
        this.lowerLimit = lowerLimit === '~' ? -1 : lowerLimit
        this.upperLimit = upperLimit === '~' ? -1 : upperLimit
      }
      this.settlementPeriod = ''
      this.emailSetting = ''
      this.cancelable = false
      this.cancelUnit = ''
      this.cancelValue = ''
      this.threeds = false
      if (info.config_detail) {
        const configDetail = JSON.parse(info.config_detail)
        const emailNotifySetting = configDetail.email_notify_setting
        const payerVerification = configDetail.payer_verification
        const { cancellation, threeds } = configDetail
        if (!emailNotifySetting || emailNotifySetting === '') {
          this.emailSetting = ''
        } else {
          this.emailSetting = emailNotifySetting.split(' ')
        }
        const settlementPeriod = configDetail.settlement_period
        if (!settlementPeriod || settlementPeriod === '') {
          this.settlementPeriod = ''
        } else {
          this.settlementPeriod = settlementPeriod
        }
        if (cancellation) {
          this.cancelable = true
          this.cancelUnit = cancellation.slice(-1)
          this.cancelValue = cancellation.slice(0, -1)
        }
        if (threeds) {
          this.threeds = threeds
        }
        if (payerVerification) {
          this.payerVerification = true
          if (payerVerification.threshold) {
            this.payerVerificationThreshold = payerVerification.threshold
            if (payerVerification.flexible) {
              this.pvFlexible = true
              this.payerVerificationFlexibleThreshold = payerVerification.flexible.threshold
              this.pvFlexibleTrans = payerVerification.flexible.limit
            }
          } else {
            this.payerVerificationThreshold = payerVerification
          }
        }
        this.payerVerificationBackupChannelGroup = configDetail.pv_group_id
      }
      this.keyInfo = {
        appId: info.app_id,
        region: info.region,
        channel: info.channel,
        method: info.method,
      }
    },
    initValues() {
      this.appChannelInfo = {
        isNew: true,
        appId: '',
        channel: '',
        useChannelRouter: false,
        channelId: '',
        channelGroupId: '',
        channelName: '',
        customName: '',
        method: '',
        sort: '0',
        status: '0',
      }
      this.lowerLimit = -1
      this.upperLimit = -1
      this.emailSetting = ''
    },
    validationForm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.update()
        }
      })
    },
    update() {
      const configDetail = {}
      if (this.emailSetting) {
        configDetail.email_notify_setting = this.emailSetting.join(' ')
      }
      if (this.cancelable) {
        configDetail.cancellation = `${this.cancelValue}${this.cancelUnit}`
      }
      if (this.settlementPeriod && this.settlementPeriod !== 0) {
        configDetail.settlement_period = this.settlementPeriod
      }
      if (this.threeds) {
        configDetail.threeds = this.threeds
      }
      if (this.payerVerification) {
        const pv = {}
        if (this.pvFlexible && this.payerVerificationFlexibleThreshold > 0) {
          const flexible = {}
          flexible.threshold = this.payerVerificationFlexibleThreshold
          flexible.limit = this.pvFlexibleTrans
          pv.flexible = flexible
        }
        pv.threshold = this.payerVerificationThreshold
        configDetail.payer_verification = pv
      }
      configDetail.pv_group_id = this.payerVerificationBackupChannelGroup

      const lower = parseInt(this.lowerLimit, 0) === -1 ? '~' : this.lowerLimit
      const upper = parseInt(this.upperLimit, 0) === -1 ? '~' : this.upperLimit
      let capacity = `${lower},${upper}`
      if (capacity === '~,~') {
        capacity = ''
      }

      const params = {
        key_app_id: this.keyInfo.appId,
        key_region: this.keyInfo.region,
        key_method: this.keyInfo.method,
        key_channel: this.keyInfo.channel,
        app_id: this.appChannelInfo.appId,
        region: this.appChannelInfo.region,
        method: this.appChannelInfo.method,
        channel: this.appChannelInfo.channel,
        custom_name: this.appChannelInfo.customName,
        use_channel_group: this.useChannelRouter,
        channel_group_id: this.appChannelInfo.channelGroupId,
        channel_id: this.appChannelInfo.channelId,
        channel_name: this.appChannelInfo.channelName,
        sort: this.appChannelInfo.sort,
        config_detail: JSON.stringify(configDetail),
        capacity,
        status: this.appChannelInfo.status,
      }

      if (!this.appChannelInfo.isNew) {
        updateAppChannel(
          params,
          () => {
            showAlert(this, 'success', 'Success!', `${params.method} updated.`)
            this.$emit('update:is-app-channel-list-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      } else {
        addAppChannel(
          params,
          () => {
            showAlert(this, 'success', 'Done!', `${params.method} created.`)
            this.$emit('update:is-app-channel-list-sidebar-active', false)
            this.$emit('updated')
          },
          fail => {
            showAlert(this, 'warning', 'Fail!', `${fail}`)
          },
        )
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
